import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Chip,
  Box,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

function ArticleAdminArticle({ open, onClose, onSave }) {
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [areasOfInterest, setAreasOfInterest] = useState(['Grocery (4)', 'Grocery (4)']);
  const [associatedTags, setAssociatedTags] = useState(['Analytics', 'Analytics']);
  const [description, setDescription] = useState('');
  const [body, setBody] = useState('');
  const [files, setFiles] = useState(null);

  const handleSave = () => {
    const articleData = {
      title,
      link,
      areasOfInterest,
      associatedTags,
      description,
      body,
      files,
    };
    onSave(articleData);
    resetForm();
    onClose();
  };

  const resetForm = () => {
    setTitle('');
    setLink('');
    setDescription('');
    setBody('');
    setFiles(null);
  };

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle style={{ backgroundColor: '#070707', color: '#fff', textAlign: 'center', fontFamily: "Urbanist" }}>
        Add Article
      </DialogTitle>
      <DialogContent style={{ backgroundColor: '#070707', color: '#fff', padding: '3rem 5rem 5rem 3rem' }}>
        <TextField
          label="Title"
          fullWidth
          variant="outlined"
          margin="normal"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          InputLabelProps={{ style: { color: '#fff' } }}
          InputProps={{ style: { color: '#fff' } }}
        />
        <TextField
          label="Link"
          fullWidth
          variant="outlined"
          margin="normal"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          InputLabelProps={{ style: { color: '#fff' } }}
          InputProps={{ style: { color: '#fff' } }}
        />
        <Box marginY={2}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>Areas of Interest</span>
            <IconButton>
              <EditIcon style={{ color: 'rgb(var(--global-color-primary))' }} />
            </IconButton>
          </div>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', marginTop: 1 }}>
            {areasOfInterest.map((area, index) => (
              <Chip key={index} label={area} style={{ backgroundColor: '#070707', fontWeight: '700', border: '1px solid rgb(vat(--global-color-primary))', color: 'rgb(var(--global-color-primary))' }} />
            ))}
          </Box>
        </Box>
        <Box marginY={2}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>Associated Tags</span>
            <IconButton>
              <EditIcon style={{ color: 'rgb(var(--global-color-primary))' }} />
            </IconButton>
          </div>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', marginTop: 1 }}>
            {associatedTags.map((tag, index) => (
              <Chip key={index} label={tag} style={{ backgroundColor: '#070707', fontWeight: '700', border: '1px solid rgb(vat(--global-color-primary))', color: 'rgb(var(--global-color-primary))' }} />
            ))}
          </Box>
        </Box>
        <TextField
          label="Description"
          fullWidth
          multiline
          rows={2}
          variant="outlined"
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          InputLabelProps={{ style: { color: '#fff' } }}
          InputProps={{ style: { color: '#fff' } }}
        />
        <TextField
          label="Body"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          margin="normal"
          value={body}
          onChange={(e) => setBody(e.target.value)}
          InputLabelProps={{ style: { color: '#fff' } }}
          InputProps={{ style: { color: '#fff' } }}
        />
        <Button variant="contained" component="label" style={{ backgroundColor: 'rgb(var(--global-color-primary))', color: '#000' }}>
          Choose Files
          <input type="file" hidden onChange={handleFileChange} />
        </Button>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#070707', justifyContent: 'space-between', padding: '0 5rem 5rem 3rem' }}>
        <Button onClick={onClose} variant="contained" style={{ backgroundColor: '#808080', color: '#fff' }}>
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" style={{ backgroundColor: 'rgb(var(--global-color-primary))', color: '#000' }}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ArticleAdminArticle;
