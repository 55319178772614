import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Grid } from "@mui/material";

const CompanyInformationDialog = ({ open, handleClose, innovationId }) => {
  const [formData, setFormData] = useState({
    hqCountry: "",
    city: "",
    accountType: "",
    legalName: "",
    brandName: "",
    founder: "",
    phone: "",
    linkedin: "",
    website: "",
    founded: "",
    totalFunding: "",
    companyStatus: "",
    companyType: "",
    businessModel: "",
    targetMarket: "",
    otherCountries: "",
    integrationTiming: "",
    clients: "",
    traction: "",
    clientList: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/innovations/${innovationId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Data updated successfully!");
        handleClose();
      } else {
        alert("Failed to update data.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      alert("An error occurred.");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold", backgroundColor: "#191919", color: "white", textAlign: "center"}}>
        Company Information
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#191919", color: "white", padding: "24px", height: "80vh" }}>
        <div style={{height:"10vh"}} />
        <Grid container spacing={3}>
          {/* First Column */}
          <Grid item xs={12} md={4}>
            <TextField
              name="hqCountry"
              label="HQ Country"
              select
              fullWidth
              variant="outlined"
              value={formData.hqCountry}
              onChange={handleInputChange}
              sx={textFieldStyle}
            >
              <MenuItem value="US">United States</MenuItem>
              <MenuItem value="IT">Italy</MenuItem>
              {/* Add more options as needed */}
            </TextField>
            <TextField
              name="legalName"
              label="Legal Name"
              fullWidth
              variant="outlined"
              value={formData.legalName}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />
            <TextField
              name="phone"
              label="Phone"
              fullWidth
              variant="outlined"
              value={formData.phone}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />
            <TextField
              name="founded"
              label="Founded"
              fullWidth
              variant="outlined"
              value={formData.founded}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />
            <TextField
              name="businessModel"
              label="Business Model"
              select
              fullWidth
              variant="outlined"
              value={formData.businessModel}
              onChange={handleInputChange}
              sx={textFieldStyle}
            >
              <MenuItem value="subscription">Subscription</MenuItem>
              <MenuItem value="freemium">Freemium</MenuItem>
              {/* Add more options */}
            </TextField>
            <TextField
              name="numberOfClients"
              label="Number of Clients"
              fullWidth
              variant="outlined"
              value={formData.numberOfClients}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />
          </Grid>

          {/* Second Column */}
          <Grid item xs={12} md={4}>
            <TextField
              name="city"
              label="City"
              fullWidth
              variant="outlined"
              value={formData.city}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />
            <TextField
              name="brandName"
              label="Brand Name"
              fullWidth
              variant="outlined"
              value={formData.brandName}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />
            <TextField
              name="linkedin"
              label="LinkedIn Company Profile"
              fullWidth
              variant="outlined"
              value={formData.linkedin}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />
            <TextField
              name="totalFunding"
              label="Total Funding Amount"
              fullWidth
              variant="outlined"
              value={formData.totalFunding}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />
            <TextField
              name="companyStatus"
              label="Company Status"
              select
              fullWidth
              variant="outlined"
              value={formData.companyStatus}
              onChange={handleInputChange}
              sx={textFieldStyle}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
              {/* Add more options */}
            </TextField>
            <TextField
              name="integrationTiming"
              label="Integration Timing"
              fullWidth
              variant="outlined"
              value={formData.integrationTiming}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />
          </Grid>

          {/* Third Column */}
          <Grid item xs={12} md={4}>
            <TextField
              name="accountType"
              label="Account Type"
              select
              fullWidth
              variant="outlined"
              value={formData.accountType}
              onChange={handleInputChange}
              sx={textFieldStyle}
            >
              <MenuItem value="standard">Standard</MenuItem>
              <MenuItem value="premium">Premium</MenuItem>
              {/* Add more options */}
            </TextField>
            <TextField
              name="founder"
              label="Founder"
              fullWidth
              variant="outlined"
              value={formData.founder}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />
            <TextField
              name="website"
              label="URL of Company Website"
              fullWidth
              variant="outlined"
              value={formData.website}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />
            <TextField
              name="companyType"
              label="Company Type"
              select
              fullWidth
              variant="outlined"
              value={formData.companyType}
              onChange={handleInputChange}
              sx={textFieldStyle}
            >
              <MenuItem value="llc">LLC</MenuItem>
              <MenuItem value="corporation">Corporation</MenuItem>
              {/* Add more options */}
            </TextField>
            <TextField
              name="targetMarket"
              label="Target Market"
              select
              fullWidth
              variant="outlined"
              value={formData.targetMarket}
              onChange={handleInputChange}
              sx={textFieldStyle}
            >
              <MenuItem value="europe">Europe</MenuItem>
              <MenuItem value="asia">Asia</MenuItem>
              {/* Add more options */}
            </TextField>
            <TextField
              name="otherCountries"
              label="Presence in Other Countries"
              select
              fullWidth
              variant="outlined"
              value={formData.otherCountries}
              onChange={handleInputChange}
              sx={textFieldStyle}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        {/* Additional Fields */}
        <TextField
          name="traction"
          label="Traction"
          fullWidth
          variant="outlined"
          value={formData.traction}
          onChange={handleInputChange}
          sx={{ ...textFieldStyle, marginTop: "24px" }}
        />
        <TextField
          name="clientList"
          label="Clients List"
          fullWidth
          variant="outlined"
          value={formData.clientList}
          onChange={handleInputChange}
          sx={textFieldStyle}
        />
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#191919", padding: "16px" }}>
        <Button onClick={handleClose} sx={{ color: "white" }}>
          Cancel
        </Button>
        <Button
          onClick={handleUpdate}
          sx={{
            backgroundColor: "rgb(var(--global-color-primary))",
            color: "black",
            fontWeight: "bold",
            "&:hover": { backgroundColor: "rgba(var(--global-color-primary), 0.8)" },
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const textFieldStyle = {
    marginBottom: "16px",
  "& .MuiInputBase-root": { color: "white" },
  "& .MuiFormLabel-root": { color: "rgba(255, 255, 255, 0.7)" },
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
    "&:hover fieldset": { borderColor: "white" },
    "&.Mui-focused fieldset": { borderColor: "rgb(var(--global-color-primary))" },
  },
};

export default CompanyInformationDialog;
