import React from "react";
import { Card, CardMedia, CardContent, Typography, Skeleton } from "@mui/material";

function StartupsCardSkeleton() {
  return (
    <Card
      className="flex flex-col p-5 border border-solid border-opacity-30 relative"
      sx={{ color: "white", backgroundColor: "transparent", height: "100%", borderColor: "grey" }}
    >
      {/* Placeholder for the Bookmark Icon */}
      <Skeleton variant="circular" width={24} height={24} sx={{ position: "absolute", top: "10px", right: "10px", backgroundColor: "grey" }} />

      <div className="flex gap-4 items-center">
        {/* Placeholder for Logo */}
        <Skeleton
          variant="rectangular"
          width={80}
          height={80}
          className="rounded-lg"
          sx={{backgroundColor: "grey"}}
        />
        <CardContent>
          {/* Placeholder for Title */}
          <Typography variant="h6" sx={{ fontWeight: "bold", fontFamily: "Urbanist, sans-serif" }}>
            <Skeleton width="60%" sx={{backgroundColor: "grey"}}/>
          </Typography>
          {/* Placeholder for Description */}
          <Typography variant="body2" sx={{ fontFamily: "Urbanist, sans-serif", maxHeight: "100px", overflow: "hidden" }}>
            <Skeleton width="150px" sx={{backgroundColor: "grey"}}/>
            <Skeleton width="150px" sx={{backgroundColor: "grey"}}/>
            <Skeleton width="150px" sx={{backgroundColor: "grey"}}/>
          </Typography>
        </CardContent>
      </div>
      <div className="flex w-full justify-between items-center">
        {/* Placeholder for Tags */}
        <div className="flex flex-wrap w-full gap-2 items-center">
          {[...Array(3)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rounded"
              width={70}
              height={25}
              sx={{ marginBottom: "5px", backgroundColor: "grey" }}
            />
          ))}
        </div>
        {/* Placeholder for Country */}
        <div className="flex flex-col gap-2 items-center">
          <Skeleton width={50} height={15} sx={{backgroundColor: "grey"}} />
          <Skeleton variant="rectangular" width={30} height={20} sx={{backgroundColor: "grey"}} />
        </div>
      </div>
    </Card>
  );
}

export default StartupsCardSkeleton;
