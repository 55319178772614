import * as React from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

export default function StandardSelect({ label, items, value, onChange, multiple, disabled }) {
  return (  
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label" sx={{ color: "white" }}>{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        disabled={disabled || false}
        value={value}
        label={label}
        onChange={onChange}
        multiple={!!multiple ? multiple : false}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300, // Limita l'altezza del menu a 300px
              width: '500px', // Allinea la larghezza con il campo select
              backgroundColor: '#333', // Esempio di personalizzazione del colore di sfondo
              color: '#fff', // Colore del testo
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        sx={{
          borderColor: "white",
          '& .MuiSelect-root': {
            '& fieldset': {
              borderColor: '#fff',
              fontFamily: 'Urbanist, sans-serif',
            },
            '&:hover fieldset': {
              borderColor: '#fff', 
            },
            '&.Mui-focused fieldset': {
              borderColor: '#fff', 
            },
          },
          '& .MuiOutlinedInput-input': {
            color: '#fff', 
            fontFamily: 'Urbanist, sans-serif',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            color: '#fff', 
            borderColor: "white",
            fontFamily: 'Urbanist, sans-serif',
          },
          '& .MuiInputLabel-root': {
            color: '#fff',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#fff',
          },
          '& .Mui-disabled': {
            color: '#fff',
            '-webkit-text-fill-color': 'rgb(255, 255, 255, .5) !important'
          },
          '& .MuiSvgIcon-root-MuiSelect-icon': {
            fill: "white"
          }
        }}
      >
        {
          items.map((r) => 
            <MenuItem value={r.id} key={r.id}>{r.name}</MenuItem>
          )
        }
      </Select>
    </FormControl>
  )
}
