import React, { useState, useEffect } from 'react';
import BlogSection from '../blogSection';
import axios from 'axios';
import { Typography, Pagination, Button } from '@mui/material';
import SkeletonCard from "../skeleton/skeletonCard";
import ArticleAdminArticle from './dialogs/articleAdminArticle';

function ArticlesAdmin () {
    const [newsData, setNewsData] = useState([]); // Holds the fetched news articles
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [page, setPage] = useState(1); // Current page
    const [totalPages, setTotalPages] = useState(1); // Total pages from the API
    const [perPage] = useState(9); // Results per page
    const [openDialog, setOpenDialog] = useState(false);
    const [activeNewsFilter, setActiveNewsFilter] = useState('Startup News'); 
  
    // Function to get the news endpoint based on active filter
    const getNewsEndpoint = () => {
      return `${process.env.REACT_APP_API_URL}/news`;
    };
  
    // Function to fetch data
    const fetchData = async (page) => {
      setLoading(true);
      setError(null);
  
      try {
        const newsUrl = getNewsEndpoint();
        const response = await axios.get(newsUrl, {
          params: { page, per_page: perPage },
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
  
        const data = response.data;
        setNewsData(data.data); // Set the fetched news articles
        setTotalPages(data.last_page); // Set the total pages for pagination
      } catch (err) {
        setError(err.message || 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };
  
    // Handle page change for pagination
    const handlePageChange = (event, value) => {
      setPage(value); // Update the page number
    };
    
      
    // Fetch data when the page or activeNewsFilter changes
    useEffect(() => {
        fetchData(page);
      }, [page, activeNewsFilter]);

    const handleAddArticle = (newArticle) => {
        // Simulate adding the new article
        setNewsData((prevData) => [newArticle, ...prevData]);
        setOpenDialog(false);
      };
  
    return (
      <div className="flex flex-row flex-wrap gap-5" style={{ padding: '25px' }}>
        <div className="bg-black-l p-5 flex flex-row basis-full gap-5 justify-between">


            <h3>Related Articles</h3>
            <Button
              onClick={() => setOpenDialog(true)}
              sx={{
                color: '#fff',
                textTransform: 'none',
                fontFamily: 'Urbanist, sans-serif',
                fontSize: '18px',
                fontWeight: '400',                                  
                    '&:hover': {
                      color: 'rgb(var(--global-color-primary))',
                      backgroundColor: 'transparent'
                    },
              }}
            >
              + Add New Article
            </Button>

            {/* Dialog for adding a new article */}
            <ArticleAdminArticle
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              onSave={handleAddArticle}
            />

  
          
          </div>
  
          {/* Handle loading and error states */}
          <div
            className="grid gap-3"
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 3fr)',
              gridGap: '15px',
              width: '100%',
            }}
          >
          {loading && [...Array(9)].map((_, index) => <SkeletonCard key={index} />)}
          </div>
          {error && <Typography color="error">{error}</Typography>}
  
          {/* Display the BlogSection with news articles */}
          {!loading && !error && (
            <div className="flex flex-row gap-3">
              <BlogSection articles={newsData} postsPerPage={perPage} showPagination={false} />
            </div>
          )}
  
          {/* Pagination Component */}
          <div className="flex justify-center mt-6">
            <Pagination
              count={totalPages} // Total pages from the API
              page={page} // Current page
              onChange={handlePageChange} // Handle page change
              color="primary"
              className="pagination"
            />
          </div>
        </div>
    );
  }
  
  export default ArticlesAdmin;