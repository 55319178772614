import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Button, TextField } from "@mui/material";

export default function LoadAdminVideo() {
  const [file, setFile] = useState(null); // Per gestire il file caricato
  const [filePreview, setFilePreview] = useState(null); // Per mostrare l'anteprima del file
  const [videoURL, setVideoURL] = useState(""); // URL del video
  const [videoPreview, setVideoPreview] = useState(false); // Per mostrare l'anteprima del video
  const [videoTitle, setVideoTitle] = useState("Video Title");
  const [videoDescription, setVideoDescription] = useState(
    "Video Description"
  );

  // Gestione dell'upload dei file
  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);

    if (uploadedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFilePreview(e.target.result);
      };
      reader.readAsDataURL(uploadedFile);
    }
  };

  // Gestione dell'URL del video
  const handleVideoURLChange = (e) => {
    const url = e.target.value;
    setVideoURL(url);

    // Mostra l'anteprima del video solo se è un link di YouTube valido
    if (ReactPlayer.canPlay(url)) {
      setVideoPreview(true);
    } else {
      setVideoPreview(false);
    }
  };

  return (
    <div className="min-h-screen text-white p-10 flex flex-col gap-10">
      <div className="grid grid-cols-2 gap-8">
        
        {/* Sezione sinistra per il file upload */}
        <div className="flex flex-col items-center gap-4">
          {filePreview ? (
            <div>
              <img
                src={filePreview}
                alt="File Preview"
                className="rounded-lg w-full h-64 object-cover"
              />
            </div>
          ) : (
            <div className="flex justify-center items-center bg-gray-700 w-full h-64 rounded-lg">
              <p>No file uploaded</p>
            </div>
          )}

          <input
            type="file"
            accept="image/*,video/*"
            id="file-upload"
            className="hidden"
            onChange={handleFileUpload}
          />
          <div className="flex flex-col">
            <h2 className="text-4xl font-semibold m-0">File</h2>
            <p className="mb-1">Upload a marketing file you would like to share on your profile</p>
            <p className="italic font-light text-sm">Max size of files should be no more than 5MB. Possible extensions: csv, doc, docx, pdf, pps, ppt, pptx, rtf, txt, xls, xlsx, bmp, gif, jpeg, jpg, png.</p>
          </div>
          <label htmlFor="file-upload">
            <Button
              variant="contained"
              component="span"
              style={{
                backgroundColor: "rgb(var(--global-color-primary))",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Choose File
            </Button>
          </label>
        </div>

        {/* Sezione destra per il video */}
        <div className="flex flex-col gap-4">
          <TextField
            fullWidth
            label="Add a URL or 'embed code' to the video:"
            variant="outlined"
            value={videoURL}
            onChange={handleVideoURLChange}
            InputLabelProps={{
              style: { color: "white" },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(255,255,255,0.5)",
                },
                "&:hover fieldset": {
                  borderColor: "rgb(var(--global-color-primary))",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgb(var(--global-color-primary))",
                },
                input: { color: "white" },
              },
            }}
          />

          <TextField
            fullWidth
            label="Video Title"
            variant="outlined"
            value={videoTitle}
            onChange={(e) => setVideoTitle(e.target.value)}
            InputLabelProps={{
              style: { color: "white" },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(255,255,255,0.5)",
                },
                "&:hover fieldset": {
                  borderColor: "rgb(var(--global-color-primary))",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgb(var(--global-color-primary))",
                },
                input: { color: "white" },
              },
            }}
          />

          <TextField
            fullWidth
            label="Video Description"
            variant="outlined"
            value={videoDescription}
            onChange={(e) => setVideoDescription(e.target.value)}
            InputLabelProps={{
              style: { color: "white" },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(255,255,255,0.5)",
                },
                "&:hover fieldset": {
                  borderColor: "rgb(var(--global-color-primary))",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgb(var(--global-color-primary))",
                },
                input: { color: "white" },
              },
            }}
          />

          {videoPreview && (
            <div className="mt-4">
              <ReactPlayer
                url={videoURL}
                controls
                width="100%"
                height="200px"
              />
            </div>
          )}
        </div>
      </div>

      {/* Pulsante per il salvataggio */}
      <div className="flex justify-end">
        <Button
          variant="contained"
          style={{
            backgroundColor: "rgb(var(--global-color-primary))",
            color: "black",
            fontWeight: "bold",
            width: "150px",
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
