import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Button, Typography, Alert } from "@mui/material";
import { updateSocketAuth } from "../index";

function Auth() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
  
    try {
      // Invia la richiesta di login
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
        email: email,
        password: password,
      });
  
      //console.log(response.data);
  
      // Salva il token, user ID, e company ID nel localStorage
      const token = response.data.token;
      const user = response.data.user;
      const innovation = response.data.user?.innovations
        ? true
        : null;
      const userId = user.id;
      const accountCompleted = user.accountCompleted || false;
      const companyId = user.companies && user.companies.length > 0
        ? user.companies[0].id
        : null;
  
      // Prendi i ruoli dall'array, gestendo il caso in cui roles non sia presente o sia vuoto
      const roles = user.roles || [];
      const roleName = roles.length > 0 ? roles[0].name : "unknown";
  
      // Prendi le permission dell'utente se il ruolo è "admin" o "system"
      const adminPermissions = roleName === "admin" && user.permissions
        ? user.permissions.find(p => p.name === "system")?.name
        : roleName === 'admin' ? 'admin' : 'unknown';
  
      //console.log("Ruolo:", roleName);
      //console.log("Permessi admin:", adminPermissions);
  
      // Salva i valori nel localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("userId", userId);
      localStorage.setItem("companyId", companyId);
      localStorage.setItem("roleName", roleName);
      localStorage.setItem("account_completed", accountCompleted);
      localStorage.setItem("innovation", innovation);
      //localStorage.setItem("adminPermissions", JSON.stringify(adminPermissions));

      // Aggiorna il token del socket e riconnetti
      updateSocketAuth(token);
  
      // Imposta lo stato di successo e reindirizza alla dashboard o percorso desiderato
      setSuccess(true);
  
      // Reindirizza alla home o a una route protetta
      navigate("/");
    } catch (err) {
      setError("Login failed. Please check your credentials.");
      console.error("Error:", err);
    }
  };
  

  return (
    <div className="bg-black-l p-5 flex flex-col gap-10 min-h-[90vh]">
      <Box
        component="form"
        onSubmit={handleLogin}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "166px",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>

        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          margin="normal"
          fullWidth
          required
        />

        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          variant="outlined"
          margin="normal"
          fullWidth
          required
        />

        {error && (
          <Alert severity="error" sx={{ width: "100%", mt: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ width: "100%", mt: 2 }}>
            Login successful! Redirecting...
          </Alert>
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          fullWidth
        >
          Login
        </Button>
      </Box>
    </div>
  );
}

export default Auth;
