import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Tab, Box } from "@mui/material";
import FlexibleDataFetcher from "../components/flexibileDataFetcher";
import StartupDetails from "../components/adminStartupDetails/startupDetails";
import AccountInformation from "../components/adminStartupDetails/accountInformation";
import AdminVideo from "../components/adminStartupDetails/loadAdminVideo";
import ArticlesAdmin from "../components/adminStartupDetails/articlesAdmin";

const StartupAdmin = () => {
  const { id } = useParams();
  const [startupData, setStartupData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0); // Stato per il tab attivo

  const handleDataFetchSuccess = (data) => {
    setStartupData(data.data);
    setLoading(false);
  };

  const handleDataFetchError = (err) => {
    setError(err.message || "Failed to fetch startup data.");
    setLoading(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Cambia il tab attivo
  };

  return (
    <div className="p-5 bg-black min-h-[100vh]">
      {/* Fetch dei dati */}
      <FlexibleDataFetcher
        endpoint={`innovations/${id}?admin=true`}
        onSuccess={handleDataFetchSuccess}
        onError={handleDataFetchError}
      />
  
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : (

        /* Sezione 1 */

        <div className="bg-black-l p-5 rounded-2xl">
            <div className="flex flex-row mb-3">
                <div className="w-2/4 flex flex-col">
                    <h1 className="text-5xl font-semibold">{startupData.name || "No Name"}</h1>
                    <img
                      src={startupData.logo_url || startupData.fixed_logo_url || `${process.env.REACT_APP_BASE_URL}/default-logo.png`}
                      alt={startupData.name || "No Logo"}
                      className="w-72 rounded-2xl"
                      style={{marginBottom:"5px", width: "50%", height: "200px", objectFit: "cover"}}
                    />
                </div>
                <div className="w-2/4 flex flex-col gap-2 items-end	justify-center">

                    <div className="flex gap-2">
                      <span className="font-semibold">Active since:</span>
                      <span className="text-[rgb(var(--global-color-primary))] capitalize">
                        {startupData.created_at 
                            ? new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              }).format(new Date(startupData.created_at))
                            : "N/A"}
                      </span>
                    </div>

                    <div className="flex gap-2">
                      <span className="font-semibold">Last Seen:</span>
                      <span className="text-[rgb(var(--global-color-primary))] capitalize">
                        {startupData.updated_at 
                            ? new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              }).format(new Date(startupData.updated_at))
                            : "N/A"}
                      </span>
                    </div>

                    <div className="flex gap-2">
                      <span className="font-semibold">Account Type:</span>
                      <span className="text-[rgb(var(--global-color-primary))] lowercase">
                        {startupData.account_type_id || "N/A"}
                      </span>
                    </div>

                    <div className="flex gap-2">
                      <span className="font-semibold">Status:</span>
                      <span className="text-[rgb(var(--global-color-primary))] lowercase">
                        ??
                      </span>
                    </div>

                </div>
            </div>

          {/* Tab Header */}
          
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            textColor="#fff"
            indicatorColor="primary"
            sx={{
                backgroundColor: "#191919", 
                borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                "& .MuiTab-root": {
                  fontFamily: "Urbanist, sans-serif", 
                  textTransform: "none", 
                  fontSize: "16px", 
                  fontWeight: "500", 
                  color: "white",
                  minWidth: "150px",
                  margin: "0 10px",
                  "&:hover": {
                    color: "rgba(255, 255, 255, 0.7)", 
                  },
                },
                "& .Mui-selected": {
                  color: "rgb(var(--global-color-primary))", 
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "rgb(var(--global-color-primary))", 
                  height: "4px", 
                  borderRadius: "2px", 
                },
              }}
          >
            <Tab label="Startup Company Details" />
            <Tab label="Account Information" />
            <Tab label="Gallery" />
            <Tab label="Articles" />
          </Tabs>
  
          {/* Tab Content */}
          <div className="mt-5">
            {activeTab === 0 && <StartupDetails startupData={startupData} />}
            {activeTab === 1 && <AccountInformation  />}
            {activeTab === 2 && <AdminVideo  />}
            {activeTab === 3 && <ArticlesAdmin />}
          </div>
        </div>
      )}
    </div>
  );
  
};

export default StartupAdmin;
