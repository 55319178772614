import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Startups from './pages/startups';
import Startup from './pages/startup';
import Blog from './pages/blog';
import ArticlePage from './components/post';
import './global.css';
import Chats from './pages/chats';
import ChatsAdmin from './pages/chatsAdmin';
import AccountInformation from './pages/accountInformations';
import CompanyDetails from './pages/companyDetails';
import BillingDetails from './pages/billingDetails';
import Dashboard from './pages/dashboard';
import StartupList from './pages/startupList';
import StartupAnalysis from './pages/startupAnalysis';
import Auth from './components/auth';
import UserList from './pages/userList';
import ExpoRivaCompetition from './pages/expoRivaCompetitionSubmition';
import CompanyMembers from './pages/companyMembers';
import Articles from './pages/articles';
import InboxMessages from './pages/inboxMessages';
import ManageKeywords from './pages/manageKeywords';
import ManageTags from './pages/manageTags';
import RolesAndPermissions from './pages/rolesAndPermissions';
import RetailhubTeam from './pages/retailhubTeam';
import PlanTypes from './pages/planTypes';
import Prices from './pages/prices';
import PaymentPlans from './pages/paymentPlans';
import PaymentReceipts from './pages/paymentReceipts';
import BookmarksStartups from './pages/bookmarksStartups'; 
import Advisory from './pages/advisory';
import ProtectedRoute from './components/protectedRoute';
import ProtectedHome from './components/protectedHome'; 
import Layout from './components/layout'; 
import Survey from './pages/surveys';
import SurveyChat from './pages/surveyChat';
import LanderSurvey from './pages/landerSurvey';
import StartupAdmin from './pages/startupAdmin';

function App({ startup }) {

  // Funzione per decidere il componente per /chats
  const ChatComponent = startup === "startup" ? ChatsAdmin : Chats;
  
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<ProtectedHome />} />
          <Route path="/dashboard" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />
          <Route path="/startups" element={
            <ProtectedRoute>
              <Startups />
            </ProtectedRoute>
          } />
          <Route path="/startupList" element={
            <ProtectedRoute>
              <StartupList />
            </ProtectedRoute>
          } />
          <Route path="/userList" element={
            <ProtectedRoute>
              <UserList />
            </ProtectedRoute>
          } />
          <Route path="/expoRivaCompetition" element={
            <ProtectedRoute>
              <ExpoRivaCompetition />
            </ProtectedRoute>
          } />
          <Route path="/startupAnalysis" element={
            <ProtectedRoute>
              <StartupAnalysis />
            </ProtectedRoute>
          } />
          <Route path="/startups/startup/:id" element={
            <ProtectedRoute>
              <Startup />
            </ProtectedRoute>
          } />
          <Route path="/chats/:id?/:name?" element={
            <ProtectedRoute>
              <ChatComponent />
            </ProtectedRoute>
          } />
          <Route path="/chatsAdmin" element={
            <ProtectedRoute>
              <ChatsAdmin />
            </ProtectedRoute>
          } />
          <Route path="/account" element={
            <ProtectedRoute>
              <AccountInformation />
            </ProtectedRoute>
          } />
          <Route path="/companyDetails" element={
            <ProtectedRoute>
              <CompanyDetails />
            </ProtectedRoute>
          } />
          <Route path="/companyMembers" element={
            <ProtectedRoute>
              <CompanyMembers />
            </ProtectedRoute>
          } />
          <Route path="/billingDetails" element={
            <ProtectedRoute>
              <BillingDetails />
            </ProtectedRoute>
          } />
          <Route path="/blog" element={<Blog />} />
          <Route path="/articles" element={
            <ProtectedRoute>
              <Articles />
            </ProtectedRoute>
          } />
          <Route path="/inboxMessages" element={
            <ProtectedRoute>
              <InboxMessages />
            </ProtectedRoute>
          } />
          <Route path="/manageKeywords" element={
            <ProtectedRoute>
              <ManageKeywords />
            </ProtectedRoute>
          } />
          <Route path="/manageTags" element={
            <ProtectedRoute>
              <ManageTags />
            </ProtectedRoute>
          } />
          <Route path="/rolesAndPermissions" element={
            <ProtectedRoute>
              <RolesAndPermissions />
            </ProtectedRoute>
          } />
          <Route path="/retailhubTeam" element={
            <ProtectedRoute>
              <RetailhubTeam />
            </ProtectedRoute>
          } />
          <Route path="/planTypes" element={
            <ProtectedRoute>
              <PlanTypes />
            </ProtectedRoute>
          } />
          <Route path="/prices" element={
            <ProtectedRoute>
              <Prices />
            </ProtectedRoute>
          } />
          <Route path="/paymentPlans" element={
            <ProtectedRoute>
              <PaymentPlans />
            </ProtectedRoute>
          } />
          <Route path="/paymentReceipts" element={
            <ProtectedRoute>
              <PaymentReceipts />
            </ProtectedRoute>
          } />
          <Route path="/bookmarks-startups" element={
            <ProtectedRoute>
              <BookmarksStartups />
            </ProtectedRoute>
          } />
          <Route path="/advisory" element={
            <ProtectedRoute>
              <Advisory />
            </ProtectedRoute>
          } />
          <Route path="/survey" element={
            <ProtectedRoute>
              <Survey />
            </ProtectedRoute>
          } />
          <Route path="/survey/chat" element={
            <ProtectedRoute>
              <SurveyChat />
            </ProtectedRoute>
          } />
          <Route path="/startupList/:id" element={
            <ProtectedRoute>
              <StartupAdmin />
            </ProtectedRoute>
          } />

          {/* Rotta per la pagina dell'articolo */}
          <Route path="/article/:id" element={<ArticlePage />} />

          {/* Rotta per ottenere il token */}
          <Route path="/auth" element={<Auth />} />

          {/* Rotta non protetta per LanderSurvey */}
          <Route path="/lander-survey" element={<LanderSurvey />} />

        </Routes>
      </Layout>
    </Router>
  );
}

export default App;