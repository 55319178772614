import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid } from "@mui/material";

const DescriptionCompany = ({ open, handleClose, innovationId }) => {
  const [formData, setFormData] = useState({
    companyDescription: "",
    solutionsProductsServices: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/innovations/${innovationId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Data updated successfully!");
        handleClose();
      } else {
        alert("Failed to update data.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      alert("An error occurred.");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold", backgroundColor: "#191919", color: "white" }}>
        Description
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#191919", color: "white", padding: "24px" }}>
        <Grid container spacing={3}>
          {/* Company Description */}
          <Grid item xs={12}>
            <TextField
              name="companyDescription"
              label="Company description"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={formData.companyDescription}
              onChange={handleInputChange}
              sx={{
                "& .MuiInputBase-root": { color: "white" },
                "& .MuiFormLabel-root": { color: "rgba(255, 255, 255, 0.7)" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                  "&:hover fieldset": { borderColor: "white" },
                  "&.Mui-focused fieldset": { borderColor: "rgb(var(--global-color-primary))" },
                },
              }}
            />
          </Grid>

          {/* Solutions, Products, and Services */}
          <Grid item xs={12}>
            <TextField
              name="solutionsProductsServices"
              label="Solutions, products and services"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={formData.solutionsProductsServices}
              onChange={handleInputChange}
              sx={{
                "& .MuiInputBase-root": { color: "white" },
                "& .MuiFormLabel-root": { color: "rgba(255, 255, 255, 0.7)" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                  "&:hover fieldset": { borderColor: "white" },
                  "&.Mui-focused fieldset": { borderColor: "rgb(var(--global-color-primary))" },
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#191919", padding: "16px" }}>
        <Button onClick={handleClose} sx={{ color: "white", fontWeight: "bold" }}>
          Cancel
        </Button>
        <Button
          onClick={handleUpdate}
          sx={{
            backgroundColor: "rgb(var(--global-color-primary))",
            color: "black",
            fontWeight: "bold",
            "&:hover": { backgroundColor: "rgba(var(--global-color-primary), 0.8)" },
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DescriptionCompany;
