import React, { useEffect, useState } from "react";
import { Chip, Fade, Slider, Button, Link, Tooltip } from "@mui/material";
import CountryFlag from "../countryFlag";
import EditIcon from '@mui/icons-material/Edit'; 
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthIcon from '@mui/icons-material/South';
import CompanyDialog from './dialogs/companyInformation';
import DescriptionDialog from './dialogs/descriptionCompany';
import DialogTags1 from './dialogs/dialogTags1';
import DialogTags2 from './dialogs/dialogTags2';

const StartupDetails = ({ startupData }) => {
  
  const [integrationTiming, setValue] = useState(3);
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  const [readDescription, setReadMoreDescription] = useState(false);
  const [readServices, setServicesDescription] = useState(false);

  const handleReadMoreDescription = () => {
    setReadMoreDescription(true);
  };
  const handleServicesDescription = () => {
    setServicesDescription(true);
  };

  const [openDialog, setOpenDialog] = useState(null);

  const handleOpenDialog = (dialogName) => setOpenDialog(dialogName);
  const handleCloseDialog = () => setOpenDialog(null);


  if (!startupData) {
    return <p>No data available.</p>;
  }

  return (
    <div className="flex flex-col gap-10 pt-3">      

      {/* Dati principali */}
      <div className="grid grid-cols-2 gap-8">

        <div className="flex flex-col gap-10">
        
            {/* Company */}

            <div className="flex flex-col gap-4 no-p-margin">

                <div className="flex flex-row items-center gap-2 ">
                    <h2 className="text-4xl font-semibold m-0">Company Information</h2>
                    <EditIcon
                        sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
                        onClick={() => handleOpenDialog("dialog1")}
                    />
                </div>

                <p><strong>Phone:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.founders ? startupData.founders.replaceAll(';', '') : "N/A"} </span></p>
                <p><strong>Founder:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.founding_year || "N/A"} </span></p>
                <p><strong>Company Type:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.company_type.name || "N/A"} </span></p>
                <p><strong>Business Model:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.business_model?.name || "N/A"} </span></p>
                <p><strong>Company Status:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.company_stage?.name || "N/A"} </span></p>
                <p>
                  <strong>Target Markets:</strong>{" "}
                  <span className="text-[rgb(var(--global-color-primary))] capitalize">
                    {startupData.target_markets?.length > 0
                      ? startupData.target_markets.map((market) => market.name).join(", ")
                      : "N/A"}
                  </span>
                </p>
                <p><strong>LinkedIn:</strong> <a className="text-[rgb(var(--global-color-primary))] capitalize" href={startupData.linkedin_url || "N/A"} target="_blank"> {startupData.linkedin_url || "N/A"} </a></p>
            </div>

            {/* Traction */}

            <div className="flex flex-col gap-4 no-p-margin">
                <div className="flex flex-row items-center gap-2 ">
                    <h2 className="text-4xl font-semibold m-0">Traction</h2>
                </div>
                <p><strong>Clients List:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.founders ? startupData.founders.replaceAll(';', '') : "N/A"} </span></p>
                <p><strong>Number of Clients:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.founding_year || "N/A"} </span></p>
                <p><strong>Presence in other counties:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.company_type.name || "N/A"} </span></p>
                <p><strong>Platform Partners:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.business_model?.name || "N/A"} </span></p>                    
            </div>
            
            {/* Total Funding */}

            <div className="flex flex-row gap-10 no-p-margin">

                <div className="flex gap-2">
                    <div className="p-2 flex justify-center items-center rounded-lg bg-[rgb(var(--global-color-primary))] h-14 w-14">
                      <NorthEastIcon sx={{ color: "black" }} />
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="font-semibold opacity-50 text-sm">Total funding:</span>
                      <div className="font-bold text-2xl">
                        $ {startupData.total_funding ? Number(startupData.total_funding).toLocaleString() : "N/A"}
                      </div>
                    </div>
                </div>

                <div className="flex flex-col items-center gap-4">
                  <h2 className="text-white text-2xl font-semibold">Integration Timing</h2>
                  <div className="flex items-center gap-4 w-full max-w-md">
                    {/* Min Label */}
                    <span className="text-gray-400 text-sm">Min</span>

                    {/* Slider with Value */}
                    <div className="relative flex flex-col items-center w-full">
                      <span
                        className="absolute -top-6 text-[rgb(var(--global-color-primary))] text-sm font-bold"
                      >
                        {integrationTiming} Months
                      </span>
                      <Slider
                        value={integrationTiming}
                        min={1}
                        max={12}
                        disabled
                        sx={{
                          color: "rgb(var(--global-color-primary))",
                          "& .MuiSlider-thumb": {
                            height: 16,
                            width: 16,
                            backgroundColor: "rgb(var(--global-color-primary))",
                            border: "2px solid white",
                          },
                          "& .MuiSlider-track": {
                            backgroundColor: "rgb(var(--global-color-primary))",
                          },
                          "& .MuiSlider-rail": {
                            backgroundColor: "gray",
                          },
                        }}
                      />
                    </div>
                    
                    {/* Max Label */}
                    <span className="text-gray-400 text-sm">Max</span>
                  </div>
                </div>
            
            </div>

        </div>

        

        <div className="flex flex-col gap-10">
            
            {/* Description */}

            <div className="flex flex-col gap-4 no-p-margin">
                <div className="flex flex-row items-center gap-2 ">
                    <h2 className="text-4xl font-semibold m-0">Description</h2>
                    <EditIcon
                        sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
                        onClick={() => handleOpenDialog("dialog2")}
                    />
                </div>
                <p 
                  className={readDescription ? "h-full overflow-auto" : " max-h-40 overflow-hidden"}
                  dangerouslySetInnerHTML={{ __html: startupData.description || "No description available" }}
                />
                {
                  !readDescription &&
                  <div className="flex gap-2 items-center hover:no-underline cursor-pointer text-[rgb(var(--global-color-primary))]" onClick={handleReadMoreDescription}>
                    <span>Read more</span>
                    <SouthIcon sx={{color: "rgb(var(--global-color-primary))"}}/>
                  </div>
                }
            </div>

            {/* Services */}

            <div className="flex flex-col gap-4 no-p-margin">
                <div className="flex flex-row items-center gap-2 ">
                    <h2 className="text-4xl font-semibold m-0">Solutions, products, and services</h2>
                </div>
                <p 
                  className={readDescription ? "h-full overflow-auto" : " max-h-40 overflow-hidden"}
                  dangerouslySetInnerHTML={{ __html: startupData.solutions || "No description available" }}
                />
                {
                  !readServices &&
                  <div className="flex gap-2 items-center hover:no-underline cursor-pointer text-[rgb(var(--global-color-primary))]" onClick={handleServicesDescription}>
                    <span>Read more</span>
                    <SouthIcon sx={{color: "rgb(var(--global-color-primary))"}}/>
                  </div>
                }
            </div>

            {/* Sectors */}

            <div className="flex flex-col gap-4 no-p-margin">
                <div className="flex flex-row items-center gap-2 ">
                    <h2 className="text-4xl font-semibold m-0">Sectors of Competence</h2>
                    <EditIcon
                        sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
                        onClick={() => handleOpenDialog("dialog3")}
                    />
                </div>
                <div className="flex flex-row items-center gap-2 ">
                      {startupData.taxonomies?.categories?.length > 0 ? (
                      startupData.taxonomies.categories.map((category) => (
                        <div key={category.id} className="flex gap-2 justify-start items-center cursor-pointer">
                          <Tooltip
                            title={
                              category.children?.length > 0
                                ? (
                                  <div>
                                    {category.children.map((child) => (
                                      <div key={child.id}>
                                        {child.name}
                                        {child.children?.length > 0 && (
                                          <div style={{ marginLeft: '15px' }}>
                                            {child.children.map((grandChild) => (
                                              <div key={grandChild.id}>
                                                {grandChild.name}
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                )
                                : "No children available"
                            }
                            arrow
                            placement="right"
                          >
                            <img src={category.logo || "N/A"} style={{width:"50px"}}></img>
                          </Tooltip>
                        </div>
                      ))
                    ) : (
                      <span>No sectors available</span>
                    )}
              </div>
            </div>

            {/* Tags */}

            <div className="flex flex-col gap-4">
              <div className="flex flex-row items-center gap-2 ">
                <h2 className="text-4xl font-semibold m-0">Tags</h2> 
                    <EditIcon
                        sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
                        onClick={() => handleOpenDialog("dialog4")}
                    />
              </div>
              <div className="flex flex-wrap gap-2">
                {startupData.taxonomies?.tags?.length > 0 ? (
                  startupData.taxonomies.tags.map((tag) => (
                    <Chip
                      key={tag.id}
                      label={tag.name}
                      sx={{
                        fontFamily: "Urbanist, sans-serif",
                        backgroundColor: "#fff",
                        color: "#191919",
                        border: "1px solid rgb(255, 255, 255, .3)",
                        fontWeight: "500",
                        ":hover": {
                            backgroundColor: 'rgb(var(--global-color-primary))',
                            cursor: "pointer"
                        }
                      }}
                    />
                  ))
                ) : (
                  <span>No tags available</span>
                )}
              </div>
            </div>

            </div>
      </div>

      {/* Company Dialog */}
      <CompanyDialog 
        open={openDialog === "dialog1"}
        handleClose={handleCloseDialog}
        innovationId={startupData.id}         
      />

      {/* Description Dialog */}
      <DescriptionDialog 
        open={openDialog === "dialog2"}
        handleClose={handleCloseDialog}
        innovationId={startupData.id}         
      />

      {/* Tags Dialog */}
      <DialogTags1 
        open={openDialog === "dialog3"}
        handleClose={handleCloseDialog}     
        startupData={startupData}
        innovationId={startupData.id} 
      />

      <DialogTags2
        open={openDialog === "dialog4"}
        handleClose={handleCloseDialog}     
        startupData={startupData}
        innovationId={startupData.id} 
      />

    </div>

    
  );
};

export default StartupDetails;
