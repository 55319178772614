import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'; // Import useParams for dynamic route
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PublicIcon from '@mui/icons-material/Public';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import StandardButton from "../components/standardButton";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Chip, Fade, Slider, Button, Link } from "@mui/material"; // Import Link for links
import CountryFlag from "../components/countryFlag";
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FlexibleDataFetcher from "../components/flexibileDataFetcher";
import axios from 'axios'; // Import axios for making API requests
import SouthIcon from '@mui/icons-material/South';
import BlogSection from '../components/blogSection';
import StartupsCard from "../components/startupCard";
import Tooltip from '@mui/material/Tooltip';
import chats from '../assets/icons/chats.png';

export default function Startup() {
  const [startupsData, setStartupsData] = useState([]);
  const { id } = useParams(); // Get the startup ID from the URL
  const [startupData, setStartupData] = useState(null);
  const [newsData, setNewsData] = useState([]);
  const [saved, setSaved] = useState(false); // Bookmark state
  const [readDescription, setReadMoreDescription] = useState(false);
  const [readServices, setServicesDescription] = useState(false);
  const [integrationTiming, setValue] = useState(3);
  const [loadingStartupData, setLoadingStartupData] = useState(true);
  const [errorStartupData, setErrorStartupData] = useState(null);
  const [similarStartups, setSimilarStartups] = useState([]);
  const [loadingSimilarStartups, setLoadingSimilarStartups] = useState(true);
  const [errorSimilarStartups, setErrorSimilarStartups] = useState(null);
  const [loadingNewsData, setLoadingNewsData] = useState(true);
  const [errorNewsData, setErrorNewsData] = useState(null);
  const [chatVisible, setChatVisible] = useState(true);

  useEffect(() => {
    if (startupData?.taxonomies?.tags?.length > 0) {
      const tagIds = startupData.taxonomies.tags.slice(0, 2).map(tag => tag.id);
      fetchSimilarStartups(tagIds);
    }
  }, [startupData]);

  // Fetch similar startups
  const fetchSimilarStartups = async (tagIds) => {
    setLoadingSimilarStartups(true);
    setErrorSimilarStartups(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/innovations?similar=${id}`);
      setSimilarStartups(response.data.data);
    } catch (error) {
      console.error('Errore nel recupero delle startup simili:', error);
      setErrorSimilarStartups(error.message || 'Failed to fetch similar startups');
    } finally {
      setLoadingSimilarStartups(false);
    }
  };

  // Fetch data
  const handleDataFetchSuccess = (data) => {
    setStartupData(data.data);
    setSaved(data.data.is_bookmarked); // Set initial bookmark state based on the data
    setLoadingStartupData(false); // Set loading to false after data is successfully fetched
  };

  // Handle data fetch error for startup data
  const handleDataFetchError = (err) => {
    console.error('Failed to fetch startup data:', err);
    setErrorStartupData(err.message || 'Failed to fetch startup data');
    setLoadingStartupData(false);
  };

  const handleSaveToggle = async () => {
    try {
      if (saved) {
        // If currently saved, send DELETE request to remove from bookmarks
        await axios.delete(`${process.env.REACT_APP_API_URL}/innovations/${id}/bookmark`);
      } else {
        // If not saved, send POST request to add to bookmarks
        await axios.post(`${process.env.REACT_APP_API_URL}/innovations/${id}/bookmark`);
      }
      setSaved(!saved); // Toggle the saved state
    } catch (error) {
      console.error('Failed to update bookmark status:', error);
    }
  };

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const handleReadMoreDescription = () => {
    setReadMoreDescription(true);
  };
  const handleServicesDescription = () => {
    setServicesDescription(true);
  };

  // Handle data fetch success for news
  const handleNewsFetchSuccess = (data) => {
    if (data && data.data) {
      setNewsData(data.data); // Save the fetched news data
    }
    setLoadingNewsData(false); // Set loading to false after news data is fetched
  };

  // Handle data fetch error for news
  const handleNewsFetchError = (err) => {
    console.error('Failed to fetch news data:', err);
    setErrorNewsData(err.message || 'Failed to fetch news data');
    setLoadingNewsData(false);
  };


  const formatAndCreateLinks = (dataString, type) => {
    if (!dataString) return "No information available";

    const itemsArray = dataString.replace(/^;|;$/g, '').split(';');

    return itemsArray.map((item, index) => (
      <Link
        key={index}
        href={`/startups?${type}=${encodeURIComponent(item)}`}
        sx={{ textDecoration: 'none', color: 'rgb(var(--global-color-primary))', marginRight: '5px' }}
      >
        {item}
        {index < itemsArray.length - 1 && ', '}
      </Link>
    ));
  };

  // Map the similarStartups to StartupsCard components
  const listStartups = similarStartups.map((s) => (
    <StartupsCard
        key={s.id}
        name={s.name}
        year={s.founding_year}
        description={s.description?.replace(/<\/?[^>]+(>|$)/g, "") || "No description available"}
        logo={s.logo_url || `${process.env.REACT_APP_BASE_URL}/default-logo.png`} // Default logo if none
        countryCode={s.country?.iso || "US"} // Fallback to "US" if no country
        tags={s.taxonomies?.tags?.length > 0 
            ? s.taxonomies.tags.map((tag, index) => ({
                id: index,
                label: tag.name || "N/A",
                variant: "outlined"
              }))
            : [{ id: 0, label: "No tags", variant: "outlined" }]} // Fallback for no tags
        link={`/startups/startup/${s.id}`} 
    />
  ));

  const idCount = similarStartups.filter(item => item.id !== undefined).length;

  // Inside your component
  const navigate = useNavigate();

  // On click handler for "Chat now" button
  const handleChatNow = () => {
    if (startupData && startupData.id && startupData.name) {
      navigate(`/chats/${startupData.id}/${startupData.name}`);
    }
  };

  function isChatVisible() {
    const innovation = localStorage.getItem("innovation");
    return innovation !== "true"; 
  }

  useEffect(() => {
    setChatVisible(isChatVisible());
  }, []); 
  

  return (
    <div className="bg-black p-5 h-full min-h-[100vh] flex flex-col gap-10 max-w-[calc(100vw-265px)]">
      <FlexibleDataFetcher
        endpoint={`innovations/${id}`} // Dynamic ID from URL
        onSuccess={handleDataFetchSuccess}
        onError={handleDataFetchError}
      />

      {loadingStartupData ? (
        <p>Loading startup details...</p>
      ) : errorStartupData ? (
        <p style={{ color: 'red' }}>{errorStartupData}</p>
      ) : startupData ? (
        <>
          <div className="flex justify-between gap-2">
            <div className="flex flex-row items-center	">
            <h1 className="text-5xl font-semibold m-0">{startupData.name || "No Name"}</h1>
              {saved ? (
                <Fade in={saved} timeout={300}>
                  <BookmarkOutlinedIcon
                    sx={{
                      color: "rgb(var(--global-color-primary))",
                      fontSize: "40px",
                    }}
                    onClick={() => handleSaveToggle()}
                  />
                </Fade>
              ) : (
                <Fade in={!saved} timeout={300}>
                  <BookmarkBorderOutlinedIcon
                    sx={{
                      color: "rgb(var(--global-color-primary))",
                      fontSize: "40px",
                    }}
                    onClick={() => handleSaveToggle()}
                  />
                </Fade>
              )}
              { startupData.chat_available && (
                <img src={chats} alt="Chats" style={{ width: "30px", height:"30px", '&:hover': { opacity: 0.7 } }} />
              )}
              
            </div>
            <div className="flex gap-2">
              

              {startupData.linkedin_url && (
                <a href={startupData.linkedin_url.startsWith('http') ? startupData.linkedin_url : "https://" + startupData.linkedin_url} target="_blank" rel="noreferrer">
                  <LinkedInIcon sx={{ color: "rgb(var(--global-color-primary))", fontSize: "40px" }} />
                </a>
              )}

              {startupData.website && (
                <a href={startupData.website.startsWith('http') ? startupData.website : "https://" + startupData.website}  target="_blank" rel="noreferrer">
                  <PublicIcon sx={{ color: "rgb(var(--global-color-primary))", fontSize: "40px" }} />
                </a>
              )}
            </div>
          </div>

          <div className="flex gap-10 flex-wrap xl:flex-nowrap">
            <div className="flex flex-col gap-5 basis-1/4">
              <div className="flex flex-col gap-2">
                <img
                  src={startupData.logo_url || startupData.fixed_logo_url || `${process.env.REACT_APP_BASE_URL}/default-logo.png`}
                  alt={startupData.name || "No Logo"}
                  className="w-72 rounded-2xl"
                  style={{marginBottom:"5px", width: "100%", height: "200px", objectFit: "cover"}}
                />
                
                {chatVisible && (

                    <StandardButton text="Chat now" variant="filled" onClick={handleChatNow} />

                )}

                <div className="flex gap-2">
                  <span className="font-semibold">Company type:</span>
                  <span className="text-[rgb(var(--global-color-primary))] lowercase">
                    <span className="text-[rgb(var(--global-color-primary))] text-xl">{startupData.founders?.replaceAll(';', '') || "N/A"}</span>
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Business model:</span>
                  <span className="text-[rgb(var(--global-color-primary))]">{startupData.business_model?.name || "N/A"}</span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Status:</span>
                  <span className="text-[rgb(var(--global-color-primary))] ">{startupData.company_stage?.name || "N/A"}</span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Target markets:</span>
                  <span className="text-[rgb(var(--global-color-primary))]">
                    {startupData.target_markets?.length > 0
                      ? startupData.target_markets.map((market, index) => (
                          <span key={market.id}>
                            {market.name}
                            {index < startupData.target_markets.length - 1 && ', '}
                          </span>
                        ))
                      : "N/A"}
                  </span>
                </div>
              </div>
                            
            </div>

            <div className="flex flex-col gap-4 basis-2/4">
              <div className="flex gap-10 flex-wrap">
                <div className="flex flex-col gap-2">
                  <span className="font-semibold">Associated Tags:</span>
                  <div className="flex flex-wrap w-full gap-2 items-center">
                    {startupData.taxonomies?.tags.length > 0 ? (
                      startupData.taxonomies.tags.map((tag, index) => (
                        <Link
                          key={index}
                          href={`/startups?&tags%5B%5D=${tag.id}`}
                          sx={{ textDecoration: 'none' }}
                        >
                          <Chip
                            label={tag.name || "N/A"}
                            variant="outlined"
                            sx={{
                              fontFamily: "Urbanist, sans-serif",
                              backgroundColor: "#fff",
                              color: "#191919",
                              border: "1px solid rgb(255, 255, 255, .3)",
                              fontWeight: "500",
                              ":hover": {
                                backgroundColor: 'rgb(var(--global-color-primary))'
                              }
                            }}
                          />
                        </Link>
                      ))
                    ) : (
                      <span>No tags available</span>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4">
                    <div className="flex flex-col gap-2">
                      <span className="font-semibold text-xl">Founder:</span>
                      <span className="text-[rgb(var(--global-color-primary))] text-xl">{startupData.founders?.replaceAll(';', '') || "N/A"}</span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="font-semibold text-xl">Founded:</span>
                      <span className="text-[rgb(var(--global-color-primary))] text-xl">{startupData.founding_year || "N/A"}</span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="font-semibold text-xl">Country:</span>
                      <CountryFlag countryCode={startupData.country?.iso || "US"} size={"30px"} />
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="p-2 flex justify-center items-center rounded-lg bg-[rgb(var(--global-color-primary))] h-14 w-14">
                      <NorthEastIcon sx={{ color: "black" }} />
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="font-semibold opacity-50 text-sm">Total funding:</span>
                      <div className="font-bold text-2xl">
                        $ {startupData.total_funding ? Number(startupData.total_funding).toLocaleString() : "N/A"}
                      </div>
                    </div>
                  </div>                  
                </div>
              </div>
              <div className="flex flex-col gap-2 text-justify list-p mt-5">
                <h2 className="text-4xl">Company Description</h2>
                <p 
                  className={readDescription ? "h-full overflow-auto" : " max-h-40 overflow-hidden"}
                  dangerouslySetInnerHTML={{ __html: startupData.description || "No description available" }}
                />
                {
                  !readDescription &&
                  <div className="flex gap-2 items-center hover:no-underline cursor-pointer text-[rgb(var(--global-color-primary))]" onClick={handleReadMoreDescription}>
                    <span>Read more</span>
                    <SouthIcon sx={{color: "rgb(var(--global-color-primary))"}}/>
                  </div>
                }
              </div>
              <div className="flex flex-col gap-2 text-justify list-p">
                <h2 className="text-4xl">Solutions, products, and services</h2>
                <p 
                  className={readDescription ? "h-full overflow-auto" : " max-h-40 overflow-hidden"}
                  dangerouslySetInnerHTML={{ __html: startupData.solutions || "No description available" }}
                />
                {
                  !readServices &&
                  <div className="flex gap-2 items-center hover:no-underline cursor-pointer text-[rgb(var(--global-color-primary))]" onClick={handleServicesDescription}>
                    <span>Read more</span>
                    <SouthIcon sx={{color: "rgb(var(--global-color-primary))"}}/>
                  </div>
                }
              </div>
            </div>

            <div className="flex flex-col gap-2 basis-1/4">
              <h2 className="text-4xl">Sectors of competence</h2>
              
              <div className="flex flex-row flex-nowrap justify-between gap-2 mb-5">
              {startupData.taxonomies?.categories?.length > 0 ? (
                startupData.taxonomies.categories.map((category) => (
                  <div key={category.id} className="flex flex-col gap-2 justify-start items-center cursor-pointer">
                    <Tooltip
                      title={
                        category.children?.length > 0
                          ? (
                            <div>
                              {category.children.map((child) => (
                                <div key={child.id}>
                                  {child.name}
                                  {child.children?.length > 0 && (
                                    <div style={{ marginLeft: '15px' }}>
                                      {child.children.map((grandChild) => (
                                        <div key={grandChild.id}>
                                          {grandChild.name}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )
                          : "No children available"
                      }
                      arrow
                      placement="right"
                    >
                      <img src={category.logo || "N/A"} style={{width:"50px"}}></img>
                    </Tooltip>
                  </div>
                ))
              ) : (
                <span>No sectors available</span>
              )}
              </div>
              <h2 className="text-4xl">Integration timing</h2>
              <div className="flex gap-4 items-center">
                <span className="min-w-fit">1 Month</span>
                <Slider
                  defaultValue={3}
                  value={integrationTiming}
                  min={1}
                  max={12}
                  sx={{ color: "rgb(var(--global-color-primary))" }}
                  onChange={handleSliderChange}
                  disabled={true}
                />
                <span className="min-w-fit">1 Year</span>
              </div>
              <span className="flex justify-center">{integrationTiming} months</span>
            </div>
          </div>
        </>
      ) : (
        <p>No startup data available.</p>
      )}

      {/* Box video */}
      <div className="flex flex-row gap-5">
        
        <div className="flex flex-col gap-5 basis-1/4">

        </div>

        <div className="flex flex-col gap-5 basis-1/2">
          
        {startupData?.platforms && startupData.platforms.trim() !== "" && (
          <div className="flex flex-col">
            <h2 className="text-4xl">Platform Partners</h2>
            <p>{formatAndCreateLinks(startupData.platforms, 'platforms')}</p>
          </div>
        )}

          
          <div className="flex flex-col">
            <h2 className="text-4xl">Client Partners</h2>
            <p>{startupData && formatAndCreateLinks(startupData.clients, 'client')}</p>
          </div>
          
        </div>
      </div>

      {/* Box Startup */}
      <div className="p-5 flex basis-full flex-col gap-5 justify-center items-center rounded-lg relative">
              <h3>Similar Startups</h3>
              <div
                className="grid gap-3"
                style={{
                  display: 'grid',
                  gridTemplateColumns: `repeat(3, 1fr)`,
                  gridGap: '15px',
                }}
              >
                <style>
                  {`
                    @media (min-width: 768px) {
                      div.grid {
                        grid-template-columns: repeat(3, 1fr);
                      }
                    }
                  `}
                </style>
                {loadingSimilarStartups ? (
                  <p>Loading similar startups...</p>
                ) : errorSimilarStartups ? (
                  <p style={{ color: 'red' }}>{errorSimilarStartups}</p>
                ) : similarStartups.length > 0 ? (
                  listStartups
                ) : (
                  <p>No similar startups found</p>
                )}
              </div>
          </div>


      {/* Box Articoli */}
      <div className="p-5 flex basis-full flex-col gap-5 justify-center items-center rounded-lg relative">
          <h3>Related Articles</h3>
          <div className="flex flex-row gap-3">
              <BlogSection articles={newsData} showPagination={false} />
          </div>
          <Button component="a" href="/blog" variant="contained" sx={{ fontSize: '16px', fontFamily: 'Urbanist, sans-serif', backgroundColor: '#C5FF55', color: '#191919', fontWeight: 700, textTransform: 'capitalize' }}>
              View All &rarr;
          </Button>
      </div>

      {/* Fetch news data */}
      <FlexibleDataFetcher
        endpoint="news"
        params={{ per_page: 3 }}
        onSuccess={handleNewsFetchSuccess}
        onError={handleNewsFetchError}
      />
    </div>
  );
}
